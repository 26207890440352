if (typeof window !== "undefined") {
  window.dataLayer = window.dataLayer || []
}

function gtag() {
  window.dataLayer.push(arguments)
}

export function setupConsent() {
  if (localStorage.getItem("consentMode") === null) {
    gtag("consent", "default", {
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      ad_personalization: "denied",
      functionality_storage: "denied",
      security_storage: "denied",
    })
  } else {
    gtag("consent", "default", JSON.parse(localStorage.getItem("consentMode")))
  }
}

export function setConsent(consent) {
  const consentMode = {
    ad_storage: consent.marketing ? "granted" : "denied",
    ad_user_data: consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    ad_personalization: consent.preferences ? "granted" : "denied",
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
  }
  gtag("consent", "update", consentMode)
  localStorage.setItem("consentMode", JSON.stringify(consentMode))
}

export function shouldDisplayBanner() {
  return localStorage.getItem("consentMode") === null
}

const GTAG_TRACK_KEY = "backervoice-gtag-tracker"

const tracker = () => JSON.parse(localStorage.getItem(GTAG_TRACK_KEY)) || {}

const updateTracker = (code, value) => {
  const gtagTracker = tracker()

  gtagTracker[code] = value
  localStorage.setItem(GTAG_TRACK_KEY, JSON.stringify(gtagTracker))
}

const track = code => {
  const gtagTracker = tracker()

  updateTracker(code, gtagTracker[code] + 1 || 1)
}

const wasTracked = code => {
  const gtagTracker = tracker()

  return gtagTracker[code] > 0
}

export const trackConversions = (
  { send_to, value, currency, ref } = {},
  conversions = []
) => {
  if (wasTracked(ref)) {
    return
  }

  conversions.forEach(({ user_data, event_id }) => {
    gtag("set", "user_data", user_data)

    gtag("event", "conversion", {
      send_to,
      value,
      currency,
      transaction_id: event_id,
    })
  })

  track(ref)
}

export const trackSingleConversion = ({
  send_to,
  value,
  currency,
  ref,
} = {}) => {
  if (wasTracked(ref)) {
    return
  }

  gtag("event", "conversion", {
    send_to,
    value,
    currency,
  })

  track(ref)
}

export function setupGtag(tagId) {
  if (typeof window !== "undefined") {
    const script = document.createElement("script")
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`
    document.head.appendChild(script)

    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      gtag("js", new Date())
      gtag("config", tagId)

      console.log("Google Tag Manager script loaded")
    }
  }
}
